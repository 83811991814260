<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card no-body class="border mt-1">
        <b-card-body>
          <b-col xl="4">
            <b-form-group label-for="name">
              <validation-provider #default="{ errors }" name="name" rules="required">
                <label>الدور</label>

                <b-form-input
                  id="helperInput"
                  placeholder="اسم الدور"
                  v-model="roleName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <label>إضافة دور </label> -->
          <!-- <b-card-title></b-card-title> -->
          <!-- <b-card-sub-title>Permission according to roles</b-card-sub-title> -->
        </b-card-body>
        <div class="_overflow _table_div">
          <table class="table table-sm table-borderless font-small-3">
            <!-- TABLE TITLE -->
            <tr class="blue">
              <th><label> name</label></th>
              <th><label>Read</label></th>
              <th><label>create</label></th>
              <th><label>Update</label></th>
              <th><label>Delete</label></th>
            </tr>
            <!-- TABLE TITLE -->

            <!-- ITEMS -->
            <tr v-for="(r, i) in permissionsData" :key="i">
              <td>{{ r.resource }}</td>
              <td><b-form-checkbox v-model="r.read"></b-form-checkbox></td>
              <td><b-form-checkbox v-model="r.create"></b-form-checkbox></td>
              <td><b-form-checkbox v-model="r.update"></b-form-checkbox></td>
              <td><b-form-checkbox v-model="r.delete"></b-form-checkbox></td>
            </tr>
            <!-- ITEMS -->
            <!-- <div class=""> -->
            <b-button variant="purple" @click="assignRoles">إضافة الدور</b-button>
            <!-- </div> -->
          </table>
        </div>

        <br /><br />
        <!-- <b-col xl="4"></b-col> -->
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BTable,BFormInput,BFormGroup,BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
  BCard, BTable,BFormInput,BFormGroup,BButton, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
    BCard, BTable,BFormInput ,BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BCol,ValidationProvider, ValidationObserver
     ,required, ToastificationContent
  },

  data(){
    return{
  permissionsData : [
       {
        resource: 'services',
        read: false,
       update: false,
        create: false,
        delete: false,
      },
      // {
      //   resource: 'weaknesses',
      //   read: false,
      //  update: false,
      //   create: false,
      //   delete: false,
      // },
       {
        resource: 'beneficiaries',
        read: false,
       update: false,
        create: false,
        delete: false,
      },
      {
        resource: 'users ',
        read: false,
       update: false,
        create: false,
        delete: false,
      },

    ],
      roleName:''
    }
  },

  created(){
 
  },

  // setup() {
  //   const

  // },
  methods:{
    assignRoles(c){
      //console.log(c)
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules
          .validate()
          .then((success) => {
            if (success) {

      let data={
        role_name:this.roleName,
        resource_permissions:this.permissionsData
      }
      //console.log(data)
        let url=`/api/v1/information_manager/role_management/roles`
this.$http.post(url,data).then((res) => {
                //console.log(res)

                 this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم إضافة الدور بنجاح",
            icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });

                }).catch(error => {

          //console.log(error.response.data.message.errors.role_name)
              this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message.errors.role_name}`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );


      })
          }})})},
  
  }
};
</script>

<style scoped>
table tbody tr td {
  color: #00468e !important;
  font-weight: bold !important;
  font-family: "Cairo", sans-serif !important;
}
.blue {
  background-color: #00468e !important;
}
label {
  color: white !important;;
  font-weight: bold;
  font-size: 15px;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  margin-top: 100px;
  margin-left: 10px;
  background-color: #00468e !important;
  width: 200px;
}
</style>